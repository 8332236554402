







































import Vue from 'vue'
import TemplateQueries from './TemplateQueries.vue'
import TemplateSelect from './TemplateSelect.vue'
import ITemplate from '../models/ITemplate'

export default Vue.extend({
  components: {
    TemplateQueries,
    TemplateSelect
  },
  props: {
    template: Object as () => ITemplate
  },
  data() {
    return {
      replacementTemplateId: 0
    }
  },
  computed: {
    replacementTemplate(): ITemplate {
      return this.$store.getters['Templates/getTemplateById'](
        this.replacementTemplateId
      )
    },
    submitText(): string {
      return this.replacementTemplateId === 0 ? 'Delete' : 'Replace'
    }
  },
  methods: {
    templateFilter(template: ITemplate) {
      return this.template.id !== template.id
    },
    onCancel() {
      this.$emit('cancel')
    },
    onSubmit() {
      this.$emit('submit', this.replacementTemplateId)
    }
  },
  watch: {
    template: {
      handler() {
        this.replacementTemplateId = 0
      }
    }
  }
})
