
































































import Vue from 'vue'
import TemplateDialog from './TemplateDialog.vue'
import TemplateQueries from './TemplateQueries.vue'
import DeleteTemplateDialog from './DeleteTemplateDialog.vue'
import IODataTableHeader from '@/models/IODataTableHeader'
import ODataTable from '@/components/ODataTable.vue'
import { createNamespacedHelpers } from 'vuex'
import ITemplate from '../models/ITemplate'
const { mapActions } = createNamespacedHelpers('Templates')

export default Vue.extend({
  components: {
    ODataTable,
    TemplateDialog,
    DeleteTemplateDialog,
    TemplateQueries
  },
  data() {
    return {
      activeItem: null,
      deleteItem: null,
      searchText: null,
      template: null,
      expanded: []
    }
  },
  computed: {
    deleteMessage(): string {
      const name = this.deleteItem && this.deleteItem.displayName
      return `Are you sure you want to delete template '${name}'?`
    },
    headers(): IODataTableHeader[] {
      return [
        {
          text: 'Name',
          value: 'name',
          searchable: true
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: 1
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getTemplateById: 'getTemplateById',
      deleteTemplate: 'deleteTemplate',
      reassignTemplate: 'reassignTemplate',
      saveTemplate: 'saveTemplate',
      downloadTemplate: 'downloadTemplate'
    }),
    async refresh() {
      const table = this.$refs.table as any
      await table.getItems()
    },
    async onSave(item) {
      try {
        await this.saveTemplate(item)
      } catch (err) {
        this.$store.dispatch('showErrorAlert', err)
        throw err
      }
      this.activeItem = null
      this.refresh()
    },
    async onDelete(item) {
      this.deleteItem = await this.getTemplateById(item.id)
    },
    async onDeleteConfirm(replacementTemplateId) {
      if (replacementTemplateId) {
        await this.reassignTemplate({
          template: this.deleteItem,
          replacementTemplateId
        })
      } else {
        await this.deleteTemplate(this.deleteItem)
      }
      this.deleteItem = null
      this.refresh()
    },
    onRowClick(item, tableEvent) {
      tableEvent.expand(!tableEvent.isExpanded)
    },
    async onItemExpanded({ item, value }) {
      if (value) {
        this.template = await this.getTemplateById(item.id)
      }
    },
    async download(item: ITemplate) {
      this.downloadTemplate(item)
    }
  }
})
