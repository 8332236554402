<template>
  <div>
    <TemplateTable />
  </div>
</template>

<script>
import TemplateTable from '../components/TemplateTable.vue'

export default {
  components: {
    TemplateTable
  }
}
</script>
